<template>
    <div class="competition-status">
        <template v-if="isDate">
            <template v-if="$status.competitionOpen(competition)">
                <div class="mt-3">
                    <span class="small--text">Submissions close on {{ $status.submissionEndDate(competition) }}</span>
                </div>
            </template>

            <template v-else-if="$status.competitionJudging(competition)">
                <div class="mt-3">
                    <span class="small--text">Judging finishes on {{ $status.judgingEndDate(competition) }}</span>
                </div>
            </template>

            <template v-else-if="$status.competitionSubmissionClosed(competition)">
                <div class="mt-3">
                    <span class="small--text">Judging starts on {{ $status.judgingStartDate(competition) }}</span>
                </div>
            </template>
        </template>

        <template v-if="isStatus">
            <template v-if="$status.competitionOpen(competition)">
                <div class="status-cell d-flex align-center">
                    <span class="inline-tag text-uppercase active">Open</span>
                </div>
            </template>

            <template v-else-if="$status.competitionJudging(competition)">
                <div class="status-cell d-flex align-center">
                    <span class="inline-tag text-uppercase draft">Judging</span>
                </div>
            </template>

            <template v-else-if="$status.competitionSubmissionClosed(competition)">
                <div class="status-cell d-flex align-center">
                    <span class="inline-tag text-uppercase deleted">Submissions Closed</span>
                </div>
            </template>

            <template v-else-if="$status.competitionClosed(competition)">
                <div class="status-cell d-flex align-center">
                    <span class="inline-tag text-uppercase archived">Competition Closed</span>
                </div>
            </template>
        </template>
    </div>
</template>

<script>
export default {
    name: "competition-status",

    props: {
        view: {
            type: String,
            default: "date",
        },
        competition: {
            type: Object,
            default() {
                return {};
            },
        },
    },

    computed: {
        isDate() {
            return this.view == "date";
        },
        isStatus() {
            return this.view == "status";
        },
    },
};
</script>

<style lang="scss"></style>
