<template>
    <v-row>
        <v-col cols="12">
            <div class="text-field">
                <v-text-field
                    ref="name"
                    v-model="card.cardName"
                    hide-details
                    :rules="[rules.required]"
                    class="pt-0 mt-0 field"
                    color="primary"
                    dense
                    filled
                    solo
                    flat
                    placeholder="Enter the name on your card..."
                ></v-text-field>
            </div>
        </v-col>

        <v-col cols="12">
            <div id="stripe-card-number" class="stripe-field empty light--text"></div>
        </v-col>

        <v-col cols="12" sm="6">
            <div id="stripe-card-expiry" class="stripe-field empty light--text"></div>
        </v-col>

        <v-col cols="12" sm="6">
            <div id="stripe-card-cvc" class="stripe-field empty light--text"></div>
        </v-col>
    </v-row>
</template>

<script>
export default {
    props: {
        stripe: Object,
    },
    data() {
        return {
            card: {
                cardName: "",
            },
            rules: {
                required: (value) => !!value || "This field is required",
            },
        };
    },
    methods: {
        init() {
            var elements = this.stripe.elements();
            var elementStyles = {
                base: {
                    color: this.$vuetify?.theme?.dark
                        ? this.$vuetify?.theme?.themes?.dark?.dark
                        : this.$vuetify?.theme?.themes?.light?.dark,
                    fontWeight: 500,
                    // fontFamily: 'Source Code Pro, Consolas, Menlo, monospace',
                    // fontSize: '16px',
                    fontSmoothing: "antialiased",

                    "::placeholder": {
                        color: "#CCCCCC",
                    },
                    ":-webkit-autofill": {
                        color: "#e39f48",
                    },
                },
                invalid: {
                    color: this.$vuetify?.theme?.themes?.light?.error || "#880808",

                    "::placeholder": {
                        color: "#FFCCA5",
                    },
                },
            };

            var elementClasses = {
                focus: "focused",
                empty: "empty",
                invalid: "invalid",
            };

            var cardNumber = elements.create("cardNumber", {
                style: elementStyles,
                classes: elementClasses,
            });
            cardNumber.mount("#stripe-card-number");

            var cardExpiry = elements.create("cardExpiry", {
                style: elementStyles,
                classes: elementClasses,
            });
            cardExpiry.mount("#stripe-card-expiry");

            var cardCvc = elements.create("cardCvc", {
                style: elementStyles,
                classes: elementClasses,
            });
            cardCvc.mount("#stripe-card-cvc");

            this.card = {
                cardNumber,
                cardExpiry,
                cardCvc,
            };

            console.log("initialised", this.card);
        },
        getCard() {
            return this.card;
        },
    },
    mounted() {
        this.init();
    },
};
</script>

<style lang="scss">
.stripe-field {
    padding: 8px 12px;
    border-radius: 4px;
    width: 100%;
    height: 40px;
    display: flex;
    align-items: center;
    border: 1px solid rgba(#cccccc, 0.3);
    transition: all 0.2s;
    background: var(--v-grey-base);

    &.focused {
        border: 1px solid var(--v-primary-base);
    }

    &.invalid {
        border: 1px solid var(--v-error-base);
    }
}

.__PrivateStripeElement {
    width: 100%;

    iframe {
        .InputElement {
            color: var(--v-light-base) !important;
        }
    }
}

// .error svg .base {
//     fill: #fa755a;
// }

// .error svg .glyph {
//     fill: #fff;
// }

// .error .message {
//     color: #fff;
// }

// .success .icon .border {
//     stroke: #fcd669;
// }

// .success .icon .checkmark {
//     stroke: #fff;
// }

// .success .title {
//     color: #fff;
// }

// .success .message {
//     color: var(--v-success-base);
// }

// .success .reset path {
//     fill: #fff;
// }
</style>
