<template>
    <v-dialog v-model="display" persistent content-class="confirm" :fullscreen="$vuetify.breakpoint.xsOnly" width="600">
        <v-card class="entry-purchase-dialog no-selection dark--text">
            <div class="dialog-error error px-4" :class="{ active: showError }">
                <div class="text d-flex align-center">
                    <v-icon class="mr-4" color="white">mdi-alert-circle-outline</v-icon>
                    <b class="white--text small--text">{{ errorMessage }}</b>
                </div>

                <v-btn x-small icon fab color="white" @click="showError = false">
                    <v-icon>mdi-close</v-icon>
                </v-btn>
            </div>

            <div class="pa-3">
                <p class="text-center mb-0"><b v-text="title"></b></p>
            </div>

            <v-container fluid>
                <v-row>
                    <v-col cols="12">
                        <template v-if="model.competition && model.competition._id">
                            <div class="group-item pa-3">
                                <div class="d-flex align-center">
                                    <template v-if="hasImage(model.competition)">
                                        <v-img
                                            class="rounded-lg mr-3 d-block"
                                            width="100"
                                            height="70"
                                            :src="$fluro.asset.imageUrl(model.competition.data.image)"
                                        >
                                            <template v-slot:placeholder>
                                                <v-row class="fill-height grey ma-0" align="center" justify="center">
                                                    <v-progress-circular
                                                        :width="2"
                                                        :size="20"
                                                        color="dark"
                                                        indeterminate
                                                    ></v-progress-circular>
                                                </v-row>
                                            </template>
                                        </v-img>
                                    </template>

                                    <b class="small--text">{{ model.competition.title }}</b>
                                </div>

                                <v-btn color="dark" icon text @click="model.competition = {}">
                                    <v-icon>mdi-close</v-icon>
                                </v-btn>
                            </div>
                        </template>

                        <template v-else>
                            <autocomplete-field
                                :key="display"
                                :selected="competitions"
                                v-model="model.competition"
                                hide-details
                                return-object
                                :loading="loading"
                            ></autocomplete-field>
                        </template>
                    </v-col>

                    <v-col cols="12" v-if="model.competition && model.competition._id">
                        <div class="group-item pa-3 d-flex flex-column full-width">
                            <!-- <v-radio-group class="ma-0 pa-0 full-width" hide-details v-model="model.earlyBird">
                                <v-radio class="mb-0" :class="{ 'mb-6': model.earlyBird }" label="I have an early bird code" :value="true"></v-radio>

                                <template v-if="model.earlyBird">
                                    <p class="primary--text small--text">What is your code?</p>
                                    <v-otp-input hide-details v-model="model.code" :length="codeLength"></v-otp-input>
                                </template>

                                <hr />

                                <v-radio class="ma-0" :class="{ 'mb-6': !model.earlyBird }" label="I don't have an early bird code" :value="false"></v-radio>

                                <template v-if="!model.earlyBird"> -->
                            <!-- <buttons-field v-model="model.bundle" :selected="entries" title="How many entries do you want to purchase?"></buttons-field> -->
                            <template v-if="!myComp.data.freeEntry">
                                <cart-item-field
                                    v-model="model.bundles"
                                    :selected="entries"
                                    :competition="model.competition"
                                    :my-competitions="myCompetitions"
                                    title="How many entries do you want to purchase?"
                                ></cart-item-field>
                            </template>
                            <div class="full-width" v-if="tooManyEntries || tooManyEntriesFreeComp">
                                <hr class="my-6 full-width" />

                                <p class="error--text ma-0">There is a limit of {{ max }} entries per competition.</p>
                            </div>

                            <template v-if="isDigital && !myComp.data.freeEntry">
                                <hr class="my-6 full-width" />

                                <div class="full-width d-flex align-center justify-space-between">
                                    <v-switch
                                        class="ma-0 pa-0"
                                        persistent-hint
                                        :hint="`Pricing per entry is ${$filters.currency(feedbackPrice)}  ${
                                            $app.currency
                                        } `"
                                        v-model="model.feedback"
                                        inset
                                    >
                                        <template v-slot:label>
                                            <span class="small--text">I would like feedback on my entries</span>
                                        </template>
                                    </v-switch>

                                    <h4 :class="{ 'dark-grey--text': !model.feedback }">
                                        <span>{{ totalFeedbackPrice | currency }}</span>
                                        <span style="font-weight: 300">({{ $app.currency || "AUD" }})</span>
                                    </h4>
                                </div>
                            </template>

                            <template v-if="isPrint && !returnPaid">
                                <hr class="my-6 full-width" />

                                <div class="full-width d-flex align-center justify-space-between">
                                    <v-switch class="ma-0 pa-0" hide-details v-model="model.returnPrint" inset>
                                        <template v-slot:label>
                                            <span class="small--text">I would like my prints returned</span>
                                        </template>
                                    </v-switch>

                                    <h4 :class="{ 'dark-grey--text': !model.returnPrint }">
                                        <span>{{ returnPrice | currency }}</span>
                                        <span style="font-weight: 300">({{ $app.currency || "AUD" }})</span>
                                    </h4>
                                </div>

                                <div class="text-left full-width mt-3">
                                    <span class="small--text" :class="{ 'opacity-30': model.returnPrint }"
                                        >Photographs not returned will be added to the
                                        <a
                                            class="primary--text"
                                            href="https://australianphotographicprize.com.au/collection"
                                            target="_blank"
                                            >archival</a
                                        >
                                        collection</span
                                    >
                                </div>
                            </template>
                            <template v-else-if="isPrint && returnPaid">
                                <hr class="my-6 full-width" />
                                <div class="full-width d-flex align-center text-left full-width mt-3">
                                    <span class="small--text" :class="{ 'opacity-30': model.returnPrint }"
                                        >Already paid for print return.</span
                                    >
                                </div>
                            </template>
                            <hr class="my-6 full-width" />
                            <!-- Discount Code -->
                            <!-- <template>
                                <div class="full-width">
                                    <v-card
                                        class="pa-2 mb-3 background elevation-0 payment d-flex align-center justify-space-between"
                                        style="height: 56px"
                                    >
                                        <div class="d-flex align-center">
                                            <v-icon class="mr-2" color="primary">mdi-sale-outline</v-icon>
                                            <h4>Discount Code:</h4>
                                        </div>

                                        <h4>
                                            <v-text-field
                                                v-model="discountCode"
                                                color="primary"
                                                :append-icon="discountIcon"
                                                label="Enter Code"
                                            ></v-text-field>
                                        </h4>
                                    </v-card>
                                </div>
                            </template> -->
                            <!-- Redeem Code -->
                            <template v-if="!myComp.data.freeEntry">
                                <div class="full-width">
                                    <v-card
                                        class="pa-2 mb-3 background elevation-0 payment d-flex align-center justify-space-between"
                                        style="height: 56px"
                                    >
                                        <div class="d-flex align-center">
                                            <v-icon class="mr-2" color="primary">mdi-ticket-percent</v-icon>
                                            <h4>Redeem Free Entry:</h4>
                                        </div>

                                        <h4>
                                            <v-text-field
                                                v-model="redeemCode"
                                                color="primary"
                                                :append-icon="redeemIcon"
                                                label="Enter Code"
                                            ></v-text-field>
                                        </h4>
                                        <v-btn
                                            @click="redeemFreeEntry"
                                            small
                                            :disabled="!validRedeemCode || !model.acceptedTerms"
                                            color="primary"
                                            >Redeem</v-btn
                                        >
                                    </v-card>
                                </div>
                            </template>

                            <div class="p-relative group-item pa-3 mt-6" v-if="!myComp.data.freeEntry">
                                <div class="full-width">
                                    <v-card
                                        class="pa-2 mb-3 background elevation-0 payment d-flex align-center justify-space-between"
                                        style="height: 56px"
                                    >
                                        <div class="d-flex align-center">
                                            <v-icon class="mr-2" color="primary">mdi-credit-card-outline</v-icon>
                                            <h4>Amount Due:</h4>
                                        </div>

                                        <h4>
                                            <span>{{ price | currency }}</span>
                                            <span style="font-weight: 300">({{ $app.currency || "AUD" }})</span>
                                        </h4>
                                    </v-card>

                                    <stripe-element v-if="stripe" ref="stripeElement" :stripe="stripe"></stripe-element>
                                </div>

                                <v-overlay color="light" opacity="0.85" class="p-absolute" v-if="loadingStripe">
                                    <v-progress-circular :width="3" color="primary" indeterminate></v-progress-circular>
                                </v-overlay>
                            </div>
                            <div class="p-relative group-item pa-3 mt-6" v-else>
                                <div class="full-width">
                                    <v-card
                                        class="pa-2 mb-3 background elevation-0 payment d-flex align-center justify-space-between"
                                        style="height: 56px"
                                    >
                                        <div class="d-flex align-center">
                                            <v-icon class="mr-2" color="primary">mdi-ticket</v-icon>
                                            <h4>How many entries:</h4>
                                        </div>

                                        <h4>
                                            <v-text-field
                                                v-model="entryCount"
                                                color="primary"
                                                type="number"
                                                label="Entry Count"
                                            ></v-text-field>
                                        </h4>
                                        <v-btn
                                            @click="createFreeCompEntries"
                                            small
                                            :disabled="
                                                entryCount <= 0 || !model.acceptedTerms || tooManyEntriesFreeComp
                                            "
                                            color="primary"
                                            >Create Entries</v-btn
                                        >
                                    </v-card>
                                </div>
                            </div>
                            <!-- </template>
                            </v-radio-group> -->
                        </div>
                    </v-col>
                </v-row>

                <v-row v-if="model.competition && model.competition.body">
                    <v-col cols="12">
                        <div class="small--text" v-html="model.competition.body"></div>
                    </v-col>
                </v-row>

                <v-row>
                    <v-col cols="12">
                        <v-checkbox class="ma-0 pa-0" v-model="model.acceptedTerms" hide-details>
                            <template v-slot:label>
                                <span class="small--text"
                                    >I have read and accept the
                                    <span class="primary--text cursor-pointer" @click="goToTerms()"
                                        >Terms and Conditions</span
                                    >
                                    for entering the {{ orgData.title }}</span
                                >
                            </template>
                        </v-checkbox>
                    </v-col>
                </v-row>
            </v-container>

            <v-card-actions class="actions light pa-3 sticky bottom">
                <v-btn :color="cancelColor" uppercase @click="cancel"
                    ><span class="dark--text">{{ cancelLabel }}</span></v-btn
                >

                <v-btn
                    v-if="!myComp?.data?.freeEntry"
                    :disabled="!isValid"
                    :color="actionColor"
                    uppercase
                    @click="submit"
                    >{{ actionLabel }}</v-btn
                >
            </v-card-actions>

            <v-overlay color="light" opacity="0.85" class="p-absolute" v-if="loading">
                <v-progress-circular :width="3" color="primary" indeterminate></v-progress-circular>
            </v-overlay>
        </v-card>
    </v-dialog>
</template>

<script>
// Services

// Components
import AutocompleteField from "@/components/form-field/autocomplete-field.vue";
// import ButtonsField from '@/components/form-field/buttons-field.vue';
import CartItemField from "@/components/form-field/cart-item-field.vue";
import StripeElement from "@/components/global/stripe-element.vue";

// Packages
import _ from "lodash";
import moment from "moment";

export default {
    name: "entry-purchase-dialog",

    components: {
        AutocompleteField,
        // ButtonsField,
        CartItemField,
        StripeElement,
    },

    props: {
        title: {
            type: String,
            default() {
                return "Purchase Entry";
            },
        },
        actionLabel: {
            type: String,
            default() {
                return "OK";
            },
        },
        cancelLabel: {
            type: String,
            default() {
                return "Cancel";
            },
        },
        actionColor: {
            type: String,
            default() {
                return "primary";
            },
        },
        cancelColor: {
            type: String,
            default() {
                return "grey";
            },
        },
        hideAction: Boolean,
        hideCancel: Boolean,
    },

    data() {
        return {
            entryCount: null,
            redeemCode: null,
            validRedeemCode: null,
            validCode: null,
            discountCode: null,
            display: false,
            loading: false,
            loadingStripe: false,
            showError: false,
            codeLength: 6,
            stripe: null,
            message: "",
            errorMessage: "",
            realm: "",
            integration: "",
            myCompetitions: [],
            max: 22,
            model: {
                competition: {},
                earlyBird: false,
                feedback: false,
            },
            competitions: {
                minimum: 1,
                maximum: 1,
                options: [],
                title: "Select Competition",
                placeholder: "Select Competition",
                type: "competition",
                allowDisable: true,
                filters: [
                    {
                        comparator: "in",
                        key: "data.type",
                        values: ["print", "digital"],
                    },
                    {
                        key: "status",
                        comparator: "in",
                        values: ["active"],
                    },
                    {
                        comparator: "dateafter",
                        dataType: "date",
                        key: "data.purchase.endDate",
                        value: moment().format("YYYY-MM-DD"),
                    },
                    {
                        comparator: "dateafter",
                        dataType: "date",
                        key: "data.judging.startDate",
                        value: moment().format("YYYY-MM-DD"),
                    },
                    // {
                    //     comparator: "in",
                    //     key: "realms",
                    //     value: this.$app.liveRealm,
                    // },
                    {
                        comparator: "!=",
                        dataType: "boolean",
                        key: "data.purchase.closed",
                        value: true,
                    },
                    {
                        comparator: "!=",
                        dataType: "boolean",
                        key: "data.submission.closed",
                        value: true,
                    },
                ],
                select: ["title", "data", "data.bundles", "realms", "body"],
                image: true,
            },
        };
    },

    computed: {
        //For discounts
        discountIcon() {
            if (this.validCode === null) {
                return "";
            } else if (this.validCode) {
                return "mdi-check-bold";
            } else {
                return "mdi-close-thick";
            }
        },
        //For free entries
        redeemIcon() {
            if (this.redeemCode === null) {
                return "";
            } else if (this.validRedeemCode) {
                return "mdi-check-bold";
            } else {
                return "mdi-close-thick";
            }
        },
        orgData() {
            return this.$store.getters["org/getOrgData"];
        },
        myComp() {
            let selectedComp = this.model?.competition;
            return this?.myCompetitions?.find((competition) => competition?._id == selectedComp?._id) || selectedComp;
        },
        isDigital() {
            let model = this.model;

            if (model.competition) {
                let competition = model.competition;

                if (competition.data && competition.data.type && competition.data.type == "digital") {
                    model.feedback = true;
                    return true;
                }
            }

            return false;
        },
        isPrint() {
            let model = this.model;

            if (model.competition) {
                let competition = model.competition;

                if (
                    competition.data &&
                    competition.data.type &&
                    competition.data.type == "print" &&
                    competition.data.returnPrice
                ) {
                    return true;
                }
            }

            return false;
        },
        feedbackPrice() {
            let model = this.model;
            let price = model && model.competition && model.competition.data && model.competition.data.feedbackPrice;
            return price;
        },
        returnPrice() {
            let model = this.model;
            let price = model && model.competition && model.competition.data && model.competition.data.returnPrice;
            return price;
        },
        isValid() {
            let model = this.model;

            if (!model.competition || !model.competition._id) return false;

            if (model.earlyBird) {
                let codeLength = this.codeLength;
                if (!model.code || (model.code && model.code.length !== codeLength)) return false;
            } else {
                let hasBundles = model.bundles && model.bundles.length;

                if (!hasBundles) return false;

                let availableBundles = model.competition?.data?.bundles;
                const newMax = model.competition?.data?.maxEntries;
                let max = newMax || this.max || 8;
                let total = 0;
                let myComp = this.myComp;

                let entries = myComp?.entries?.length || 0;

                model.bundles.forEach((bundle) => {
                    let match = availableBundles.find((available) => available._id == bundle._id);

                    if (match?._id) {
                        total += bundle.value * match?.data?.entries;
                    }
                });

                if (total + entries > max) return false;
            }

            if (!model.acceptedTerms) return false;

            return true;
        },

        tooManyEntriesFreeComp() {
            let model = this.model;
            if (!model.competition.data.freeEntry) return false;
            const newMax = model.competition?.data?.maxEntries;
            let entries = this.myComp?.entries?.length || 0;
            let max = newMax || this.max || 8;
            console.log("MAX LIMIT", typeof this.entryCount, entries, max);
            if (parseInt(this.entryCount) + entries > max) return true;
            return false;
        },
        tooManyEntries() {
            let model = this.model;
            let availableBundles = model.competition?.data?.bundles;
            const newMax = model.competition?.data?.maxEntries;
            let max = newMax || this.max || 8;
            let total = 0;
            let myComp = this.myComp;

            let entries = myComp?.entries?.length || 0;

            if (model?.bundles?.length) {
                model.bundles.forEach((bundle) => {
                    let match = availableBundles.find((available) => available._id == bundle._id);

                    if (match?._id) {
                        total += bundle.value * match?.data?.entries;
                    }
                });

                if (total + entries > max) {
                    return true;
                }
            }

            return false;
        },
        dialogMessage() {
            if (this.showError) return this.errorMessage || "An error occured. Try again.";
            return this.message || "";
        },
        returnPaid() {
            return this.checkReturnFeePaid(this.model.competition);
        },
        entries() {
            let model = this.model;
            let bundles = model.competition && model.competition.data && model.competition.data.bundles;

            if (bundles && bundles.length) {
                return {
                    options: bundles,
                };
            }

            return {};
        },
        totalFeedbackPrice() {
            let model = this.model;

            let feedbackPrice = this.feedbackPrice;

            let bundles = model.competition && model.competition.data && model.competition.data.bundles;
            let selectedBundles = model.bundles;

            if (selectedBundles && selectedBundles.length) {
                let actualBundles = bundles.filter((bundle) =>
                    selectedBundles.some((selectedBundle) => selectedBundle._id == bundle._id)
                );

                if (actualBundles && actualBundles.length) {
                    let total = 0;

                    actualBundles.forEach((bundle) => {
                        let entries = bundle.data && bundle.data.entries;
                        let multiplier = selectedBundles.find(
                            (selectedBundle) => selectedBundle._id == bundle._id
                        ).value;

                        total += feedbackPrice * entries * multiplier;
                    });

                    return total;
                }
            }

            return 0;
        },
        price() {
            let model = this.model;
            let bundles = model.competition && model.competition.data && model.competition.data.bundles;
            let selectedBundles = model.bundles;

            let totalFeedbackPrice = this.totalFeedbackPrice;
            let returnPrice = this.returnPrice;

            if (selectedBundles && selectedBundles.length) {
                let actualBundles = bundles.filter((bundle) =>
                    selectedBundles.some((selectedBundle) => selectedBundle._id == bundle._id)
                );

                if (actualBundles && actualBundles.length) {
                    let total = 0;

                    actualBundles.forEach((bundle) => {
                        let price = bundle.data && bundle.data.price;
                        let multiplier = selectedBundles.find(
                            (selectedBundle) => selectedBundle._id == bundle._id
                        ).value;

                        total += price * multiplier;
                    });

                    //Apply the discount to the total only, does not need to be applied to the feedback / return
                    if (this.validCode) {
                        if (this.validCode.data.percentage) {
                            //Convert to decimal 10% OFF -> 0.9, same as 90% of total price
                            let percentage = this.validCode.data.discount / 100;
                            percentage = 1 - percentage;
                            total = percentage * total;
                        } else {
                            total -= this.validCode.data.discount;
                        }
                    }

                    if (model.feedback) return total + totalFeedbackPrice;
                    if (model.returnPrint) return total + returnPrice;
                    return total;
                }
            }

            return 0;
        },
    },

    methods: {
        async createFreeCompEntries() {
            let model = this.model;
            let user = this.$app.user;

            this.loading = true;
            let payload = {};
            let logo = this.getImage(this.orgData.data.logoLight);
            let options = { year: "numeric", month: "long", day: "numeric" };
            let endSubmitDate = new Date(this.myComp.data.submission.endDate).toLocaleString(undefined, options);
            let startJudgeDate = new Date(this.myComp.data.judging.startDate).toLocaleString(undefined, options);

            //create array
            const entryCountArray = [];
            for (let i = 0; i < this.entryCount; i++) {
                entryCountArray.push("entry");
            }
            payload = {
                email: user.email,
                name: user.firstName,
                userToken: this.$fluro.auth.getCurrentToken(),
                endSubmitDate: endSubmitDate,
                startJudgeDate: startJudgeDate,
                returnFeePaid: model?.competition?.data?.returnFeePaid || [],
                personaId: user.persona,
                competition: model.competition._id,
                feedback: false,
                returnPrint: model.returnPrint,
                realm: this.$app.liveRealm,
                integration: this.integration?._id,
                orgData: this.orgData,
                logo: logo,
                entryCount: entryCountArray, //Determines how many free entries the user gets
            };

            let response = await this.$fluro.api
                .post("/reaction/spark/648a41b892625a0191c32f25", payload)
                .then(({ data }) => data);

            if (response.result) {
                console.log("ERROR", response.result);
                this.showError = true;
                this.errorMessage = response.result;
                this.loading = false;
            } else if (response) {
                this.loading = false;
                this.$emit("confirm", response);
            }
        },
        checkReturnFeePaid(competition) {
            const returnFeePaid = competition?.data?.returnFeePaid || [];
            const returnPaid = returnFeePaid.includes(this.$app.user.persona);
            return returnPaid;
        },
        async checkDiscountCode(v) {
            //Look for a discount code matching the entered one
            //TO DO Should move to Serverless for security so they just cant query and get the code themselves
            //This also checks for free entry Redeem codes
            let today = new Date();
            today.setHours(0, 0, 0, 0);
            today = today.toISOString();

            let query = {
                _type: "article",
                definition: "discount",
                "data.code": v,
                realms: {
                    $in: this.$app.liveRealm,
                },
            };

            const response = await this.$fluro.api.post("content/_query", query).then(({ data }) => data);
            if (response.length) {
                const code = response[0]; //The found code
                const isUnderMax = code.data.usageCount < code.data.maxUsage;
                const isNotExpired = code.data.startDate <= today && today <= code.data.endDate;
                const isMyComp = code.data.competition.some((p) => p._id == this.myComp._id);
                const isFreeEntry = code.data.freeEntry;
                //check persona
                const persona = this.$app.user.persona;
                const personaList = code?.data?.personaList || [];
                const isUsedByPersona = personaList.some((p) => p == persona);
                if (isFreeEntry && isUnderMax && isNotExpired && isMyComp && !isUsedByPersona) {
                    this.validRedeemCode = true;
                } else if (isUnderMax && isNotExpired && isMyComp && !isUsedByPersona) {
                    this.validCode = code;
                } else {
                    this.validCode = false;
                    this.validRedeemCode = false;
                }
            } else {
                this.validCode = false;
                this.validRedeemCode = false;
            }
        },
        async redeemFreeEntry() {
            let model = this.model;
            let user = this.$app.user;

            this.loading = true;
            let payload = {};
            let logo = this.getImage(this.orgData.data.logoLight);
            let options = { year: "numeric", month: "long", day: "numeric" };
            let endSubmitDate = new Date(this.myComp.data.submission.endDate).toLocaleString(undefined, options);
            let startJudgeDate = new Date(this.myComp.data.judging.startDate).toLocaleString(undefined, options);

            payload = {
                email: user.email,
                name: user.firstName,
                userToken: this.$fluro.auth.getCurrentToken(),
                endSubmitDate: endSubmitDate,
                freeEntry: this.validRedeemCode,
                startJudgeDate: startJudgeDate,
                returnFeePaid: model?.competition?.data?.returnFeePaid || [],
                personaId: user.persona,
                discountCode: this.redeemCode,
                competition: model.competition._id,
                feedback: model.feedback,
                returnPrint: model.returnPrint,
                realm: this.$app.liveRealm,
                integration: this.integration?._id,
                orgData: this.orgData,
                logo: logo,
            };

            let response = await this.$fluro.api
                .post("/reaction/spark/6465a818ebfeb45c2f2d4a1c", payload)
                .then(({ data }) => data);

            // 6465a818ebfeb45c2f2d4a1c Test reaction
            //WORKING NO EMAIL REACTION 64643631c44bd80cc3e62fc5
            this.redeemCode = null;
            if (response.result) {
                console.log("ERROR", response.result);
                this.showError = true;
                this.errorMessage = response.result;
                this.loading = false;
            } else if (response) {
                this.loading = false;
                this.$emit("confirm", response);
            }
        },
        goToTerms() {
            window.open(`${this.orgData.data.links.terms}`, "_blank");
        },
        hasImage(item) {
            let image = item && item.data && item.data.image;

            return image?._id || image;
        },
        reset() {
            this.stripe = null;
            this.loading = false;
            this.showError = false;
            this.errorMessage = "";
            this.myCompetitions = [];
            this.model = {
                competition: {},
                bundles: [],
                earlyBird: false,
                acceptedTerms: false,
                feedback: false,
            };
        },
        async open(myCompetitions, message) {
            this.message = message;
            this.display = true;
            await this.init(myCompetitions);
        },
        async init(myCompetitions) {
            this.reset();

            this.myCompetitions = myCompetitions;

            // Turn this back on if we need competition type filter

            // let user = this.$app.user;

            // let payload = {
            //     _type: "contact",
            //     "data.personaId": user.persona,
            // };

            // let contact = await this.$fluro.api.post("/content/_query", payload).then(({ data }) => data);
            // contact = contact[0];

            // if (contact && contact._id) {
            //     let type = contact && contact.data && contact.data.type;

            //     if (type && type.length) {
            //         let print = type.some(({ key }) => key == "print");
            //         let digital = type.some(({ key }) => key == "digital");

            //         let filters = this.competitions.filters;

            //         if (!print) {
            //             filters[0].values.splice(filters[0].values.indexOf("print", 1));
            //         }

            //         if (!digital) {
            //             filters[0].values.splice(filters[0].values.indexOf("digital", 1));
            //         }
            //     }
            // }
        },
        error(message) {
            this.errorMessage = message;
            this.showError = true;
            this.loading = false;
        },
        confirm() {
            this.loading = true;
            this.$emit("confirm", this.model);
        },
        cancel() {
            this.$emit("cancel");
            this.close();
        },
        close() {
            this.display = false;
        },
        async getStripe(competition) {
            this.loadingStripe = true;
            //TO DO switch stripe integration over as well when switching the realm
            let live = competition.realms.some((realm) => realm._id == this.$app.liveRealm);
            let sandbox = competition.realms.some((realm) => realm._id == this.$app.sandboxRealm);

            try {
                let integration;

                if (live) {
                    integration = await this.$fluro.api
                        .get(`/content/integration/${this.$app.liveIntegration}`)
                        .then(({ data }) => data);
                    this.realm = this.$app.liveRealm;
                }

                if (sandbox) {
                    integration = await this.$fluro.api
                        .get(`/content/integration/${this.$app.sandboxIntegration}`)
                        .then(({ data }) => data);
                    this.realm = this.$app.sandboxRealm;
                }

                if (!integration) throw Error("Cannot find an integration");

                this.integration = integration;

                let key = integration.publicDetails.livePublicKey;

                if (integration.publicDetails.sandbox) {
                    key = integration.publicDetails.testPublicKey;
                }

                this.stripe = window.Stripe(key);
            } catch (err) {
                console.log("ERROR", err);
            }

            this.loadingStripe = false;
        },
        getImage(image) {
            if (image) {
                return this.$fluro.asset.imageUrl(image._id);
            }
        },
        async submit() {
            let model = this.model;
            this.loading = true;

            try {
                let payload = {};

                let user = this.$app.user;

                if (!user) throw new Error("Could not find a user");

                if (!model.earlyBird) {
                    let hasBundles = model.bundles && model.bundles.length;

                    if (!hasBundles) throw new Error("No bundles chosen");

                    let card = this.$refs.stripeElement.getCard();

                    let incomplete = [];

                    if (!card || !card.cardName) {
                        incomplete.push("cardName");
                    }

                    if (incomplete.length) throw new Error();

                    let token = await this.stripe
                        .createToken(card.cardNumber, { name: card.cardName, email: user.email })
                        .then((res) => res.token);

                    let logo = this.getImage(this.orgData.data.logoLight);
                    let options = { year: "numeric", month: "long", day: "numeric" };
                    let endSubmitDate = new Date(this.myComp.data.submission.endDate).toLocaleString(
                        undefined,
                        options
                    );
                    let startJudgeDate = new Date(this.myComp.data.judging.startDate).toLocaleString(
                        undefined,
                        options
                    );

                    if (token && token.id) {
                        payload = {
                            userToken: this.$fluro.auth.getCurrentToken(),
                            endSubmitDate: endSubmitDate,
                            startJudgeDate: startJudgeDate,
                            stripeToken: token.id,
                            personaId: user.persona,
                            competition: model.competition._id,
                            bundles: model.bundles,
                            earlyBird: model.earlyBird,
                            feedback: model.feedback,
                            returnPrint: model.returnPrint,
                            realm: this.realm,
                            integration: this.integration?._id,
                            orgData: this.orgData,
                            logo: logo,
                            entrantName: user.name,
                        };
                    } else {
                        throw new Error("Could not get a Stripe token");
                    }
                } else {
                    // payload = {
                    //     userToken: this.$fluro.auth.getCurrentToken(),
                    //     personaId: user.persona,
                    //     competition: model.competition._id,
                    //     earlyBird: model.earlyBird,
                    //     code: model.code,
                    //     realm: this.realm,
                    //     integration: this.integration?._id,
                    // };
                }
                if (this.validCode) {
                    payload.discountCode = this.discountCode;
                }
                let response = await this.$fluro.api
                    .post("/reaction/spark/64740bfb3f5c4866290a690e", payload)
                    .then(({ data }) => data);

                if (response.result) {
                    console.log("ERROR", response.result);
                    this.showError = true;
                    this.errorMessage = response.result;
                    this.loading = false;
                } else if (response && response?.CREATED_ENTRIES?.length) {
                    this.$emit("confirm", response);
                }
            } catch (err) {
                console.log("ERROR", err);
                this.showError = true;
                this.errorMessage = err;
                this.loading = false;
            }

            // this.loading = false;
        },
    },

    watch: {
        discountCode: {
            handler: "checkDiscountCode",
        },

        redeemCode: {
            handler: "checkDiscountCode",
        },
        "model.competition._id": async function (v) {
            if (v) {
                await this.getStripe(this.model.competition);
                this.max = this.model?.competition?.data?.maxEntries || 10;
            } else {
                this.stripe = null;
            }
        },
    },
};
</script>

<style lang="scss">
.confirm {
    background: transparent !important;
    box-shadow: 0 15px 30px rgba(0, 0, 0, 0.1) !important;
}

.entry-purchase-dialog {
    .actions {
        display: flex;
        align-items: center;

        .v-btn {
            width: 100%;
            flex-shrink: 1;
        }
    }
}
</style>
