<template>
    <div class="my-submissions d-flex full-height full-width">
        <template v-if="loading">
            <v-container fluid class="pa-6 full-height full-width">
                <div class="full-height full-width d-flex align-center justify-center">
                    <v-progress-circular :width="3" color="primary" indeterminate></v-progress-circular>
                </div>
            </v-container>
        </template>

        <template v-else>
            <template v-if="!registered">
                <v-container fluid class="pa-6 full-height full-width">
                    <div class="full-height full-width d-flex align-center justify-center">
                        <div class="d-flex flex-column align-center">
                            <p class="text-center">
                                <b
                                    >You have not registered for this competition yet, <br />click the button below to
                                    register for this competition!</b
                                >
                            </p>

                            <v-btn small class="elevet" color="primary" @click="register">
                                <v-icon>mdi-account-plus</v-icon> Register
                            </v-btn>
                        </div>
                    </div>
                </v-container>
            </template>
            <template v-else-if="myCompetitions && myCompetitions.length">
                <print-css :entry="activeEntry" :contact="contact" v-if="activeEntry"></print-css>

                <v-container fluid class="pa-6 overflow-y-scroll full-height full-width p-relative">
                    <v-row>
                        <v-col class="section-header d-flex align-start justify-space-between" cols="12">
                            <div class="d-flex align-center justify-space-between">
                                <div class="d-flex align-start">
                                    <v-menu offset-y>
                                        <template v-slot:activator="{ on, attrs }">
                                            <div class="d-flex flex-column">
                                                <div class="d-flex align-start mr-6" v-bind="attrs" v-on="on">
                                                    <h3
                                                        v-html="
                                                            activeComp && activeComp.title
                                                                ? activeComp.title
                                                                : 'All Competitions'
                                                        "
                                                    ></h3>
                                                    <v-icon class="ml-2" color="dark">mdi-chevron-down</v-icon>
                                                </div>

                                                <competition-status
                                                    :competition="activeComp"
                                                    view="date"
                                                    v-if="activeComp && activeComp._id"
                                                />
                                            </div>
                                        </template>

                                        <v-list class="list">
                                            <v-list-item
                                                color="primary"
                                                class="navigation-inactive"
                                                active-class="navigation-active"
                                                v-for="(competition, index) in competitionMenu"
                                                :key="index"
                                                @click="filterCompetitions(competition)"
                                            >
                                                <v-list-item-content>
                                                    <v-list-item-title>{{ competition.title }}</v-list-item-title>
                                                </v-list-item-content>
                                            </v-list-item>
                                        </v-list>
                                    </v-menu>

                                    <competition-status
                                        :competition="activeComp"
                                        view="status"
                                        v-if="activeComp && activeComp._id"
                                    />
                                </div>
                            </div>

                            <div class="action-buttons">
                                <v-btn
                                    class="light--text ml-3"
                                    :class="{ dark: $vuetify.breakpoint.xsOnly }"
                                    :icon="$vuetify.breakpoint.xsOnly"
                                    color="dark"
                                    small
                                    @click="openBatchSubmitDialog(activeComp)"
                                    :disabled="!allReady(activeComp)"
                                    v-if="
                                        activeComp &&
                                        activeComp._id &&
                                        $status.competitionOpen(activeComp) &&
                                        !allDone(activeComp)
                                    "
                                >
                                    <template v-if="$vuetify.breakpoint.xsOnly">
                                        <v-icon color="white" small>mdi-tray-arrow-up</v-icon>
                                    </template>

                                    <template v-else>Submit All</template>
                                </v-btn>

                                <v-btn
                                    class="ml-3"
                                    :class="{ primary: $vuetify.breakpoint.xsOnly }"
                                    :icon="$vuetify.breakpoint.xsOnly"
                                    color="primary"
                                    small
                                    @click="$refs.entryDialog.open(myCompetitions)"
                                >
                                    <template v-if="$vuetify.breakpoint.xsOnly">
                                        <v-icon color="white" small>mdi-plus</v-icon>
                                    </template>

                                    <template v-else>Purchase/Redeem Entry</template>
                                </v-btn>
                            </div>
                        </v-col>

                        <v-col cols="12">
                            <v-row v-for="competition in myCompetitions" :key="competition._id">
                                <v-col cols="12">
                                    <hr />
                                </v-col>

                                <v-col
                                    class="d-flex align-center justify-space-between full-width"
                                    cols="12"
                                    v-if="!activeComp || !activeComp._id"
                                >
                                    <div class="section-header d-flex align-start justify-space-between full-width">
                                        <div class="d-flex align-start">
                                            <div class="d-flex flex-column mr-6">
                                                <h3 class="mb-3">{{ competition.title }}</h3>

                                                <competition-status :competition="competition" view="date" />
                                            </div>

                                            <competition-status :competition="competition" view="status" />
                                        </div>

                                        <div class="action-buttons">
                                            <v-btn
                                                class="ml-3 light--text"
                                                color="dark"
                                                small
                                                :loading="competition.submitting"
                                                @click="openBatchSubmitDialog(competition)"
                                                :disabled="!allReady(competition)"
                                                v-if="$status.competitionOpen(competition) && !allDone(competition)"
                                                >Submit All</v-btn
                                            >
                                        </div>
                                    </div>
                                </v-col>

                                <v-col
                                    cols="12"
                                    sm="6"
                                    md="4"
                                    lg="2"
                                    v-for="entry in competition.entries"
                                    :key="entry._id"
                                >
                                    <entry-tile
                                        :entry="entry"
                                        :competition="competition"
                                        :view="entry.status == 'draft' ? 'empty' : 'artist'"
                                        @click="open(entry)"
                                        @print="print(entry)"
                                    />
                                </v-col>
                            </v-row>
                        </v-col>
                    </v-row>
                </v-container>
            </template>

            <template v-else>
                <v-container fluid class="pa-6 full-height full-width">
                    <div class="full-height full-width d-flex align-center justify-center">
                        <div class="d-flex flex-column align-center">
                            <p class="text-center">
                                <b>You have not purchased any entries yet, <br />click the button below to begin!</b>
                            </p>

                            <v-btn small class="elevet" color="primary" fab @click="$refs.entryDialog.open()">
                                <v-icon>mdi-plus</v-icon>
                            </v-btn>
                        </div>
                    </div>
                </v-container>
            </template>
        </template>

        <entry-purchase-dialog ref="entryDialog" @confirm="confirm"></entry-purchase-dialog>
        <alert-dialog ref="submitDialog" @confirm="submit"></alert-dialog>
    </div>
</template>

<script>
// Services
import Entry from "@/modules/app-module/entry";
import Competition from "@/modules/app-module/competition";
import Contact from "@/modules/app-module/contact";
// Components
import CompetitionStatus from "@/components/global/competition-status.vue";
import EntryTile from "@/components/global/entry-tile.vue";
import EntryPurchaseDialog from "@/components/dialogs/entry-purchase-dialog.vue";
import AlertDialog from "@/components/dialogs/alert-dialog.vue";
import PrintCss from "@/components/layout/print-css.vue";

// Packages
import moment from "moment";
import _ from "lodash";

export default {
    name: "my-submissions",

    meta: {
        title: "My Submissions",
    },

    components: {
        CompetitionStatus,
        EntryTile,
        EntryPurchaseDialog,
        AlertDialog,
        PrintCss,
    },

    data() {
        return {
            activeOrgs: [],
            registered: null,
            activeEntry: null,
            activeComp: null,
            entries: [],
            competitions: [],
            contact: {},
            loading: false,
            tabs: 0,
        };
    },

    computed: {
        myCompetitions() {
            let user = this.$app.user;

            let competitions = this.competitions;
            let entries = this.entries;

            let order = ["active", "draft", "archived", "deleted"];
            competitions = competitions
                .map((competition) => {
                    let { _id, title, data } = competition;

                    if (user) {
                        return {
                            _id,
                            title,
                            data,
                            entries: entries
                                .map((entry) => {
                                    let { _id, title, data, tags, managedOwners, status } = entry;

                                    let obj = {
                                        _id,
                                        title,
                                        data,
                                        tags,
                                        managedOwners,
                                        status,
                                    };

                                    return obj;
                                })
                                .filter((entry) => {
                                    // Entry is in competition
                                    let compMatch = competition?._id == entry?.data?.competition?._id;

                                    // Entries are not the users
                                    let currentUser = entry.managedOwners.some((owner) => owner._id == user.persona);

                                    return compMatch && currentUser;
                                })
                                .sort((a, b) => {
                                    return a?.data?.entryNumber - b?.data?.entryNumber;
                                })
                                .sort((a, b) => {
                                    return order.indexOf(a.status) - order.indexOf(b.status);
                                }),
                        };
                    }
                })
                .filter((competition) => {
                    let hasEntries = competition?.entries?.length;

                    // Is being filtered by the menu
                    let isSelected = true;
                    if (this?.activeComp?._id) {
                        isSelected = competition._id == this.activeComp._id;
                    }

                    return hasEntries && isSelected;
                })
                .sort((competition) => {
                    let submission = competition?.data?.submission;

                    let currentDate = moment().format("YYYY-MM-DD");
                    let startDate = moment(submission.startDate).format("YYYY-MM-DD");
                    let endDate = moment(submission.endDate).format("YYYY-MM-DD");

                    let relevant = currentDate >= startDate && currentDate <= endDate && !submission.closed;

                    return relevant ? -1 : 1;
                });

            return competitions;
        },
        competitionMenu() {
            let competitions = _.cloneDeep(this.myCompetitions);

            competitions.unshift({ title: "All Competitions" });

            return competitions;
        },
    },

    methods: {
        async register() {
            //Register the org of a user to their contact. This is to allow filtering by user for admins
            const payload = {
                details: {
                    registeredOrg: {
                        data: {
                            activeOrgs: [...this.activeOrgs, this.$store.getters["org/getOrg"]],
                        },
                    },
                },
            };
            const response = await Contact.update(this.$app.user.contacts[0], payload).then((this.registered = true));
            console.log(response);
        },
        async getContact() {
            const response = await this.$fluro.api
                .get(`/contact/details/${this.$app.user.contacts[0]}`)
                .then(({ data }) => data);
            const details = response;
            if (details[0]) {
                const activeOrgs = details[0].data?.activeOrgs;
                this.activeOrgs = activeOrgs;
                const registered = activeOrgs.some((p) => p._id == this.$store.getters["org/getOrg"]);
                console.log(registered);
                this.registered = registered;
                console.log("Contact", response);
            } else {
                this.registered = false;
            }
        },
        allReady(competition) {
            let entries = competition.entries.map((entry) => entry);

            return entries
                .filter((entry) => entry.status == "active" && entry?.data?.competition?._id == competition._id)
                .every((entry) => this.$status.hasEnoughInfo(entry));
        },
        allDone(competition) {
            // Need this if the user is filtering by a comp and they just did a batch submit
            competition = this.myCompetitions.find((comp) => comp?._id == competition?._id);

            let entries = competition.entries.map((entry) => entry);

            return entries
                .filter((entry) => entry.status == "active" && entry?.data?.competition?._id == competition._id)
                .every((entry) => entry?.data?.submitted);
        },
        filterCompetitions(competition) {
            this.activeComp = competition || null;
        },
        print(entry) {
            this.activeEntry = entry;

            setTimeout(() => {
                window.print();
            }, 500);
        },
        async init() {
            this.loading = true;
            await this.getContact();
            let user = this.$app.user;
            let orgData = this.$store.getters["org/getOrgData"];
            if (user) {
                try {
                    await this.$fluro.resetCache();

                    //Changed to custom query to improve loadtime
                    //Entries are drafts to begin with
                    const entryQuery = {
                        definition: "entry",
                        realms: {
                            $in: this.$app.liveRealm,
                        },
                    };
                    this.entries = await Entry.query(entryQuery).then(({ data }) => data);

                    const competitionQuery = {
                        status: "active",
                        definition: "competition",
                        realms: {
                            $in: this.$app.liveRealm,
                        },
                    };

                    console.log(this.$app.liveRealm);
                    const response = await Competition.query(competitionQuery).then(({ data }) => data);
                    this.competitions = response.reverse();
                    console.log("hello", response);
                    let payload = {
                        _type: "contact",
                        "data.personaId": user.persona,
                    };

                    let contact = await this.$fluro.api.post("/content/_query", payload).then(({ data }) => data);

                    this.contact = (contact && contact[0]) || null;
                } catch (err) {
                    console.log("ERROR", err);
                }
            }

            this.loading = false;
        },
        async confirm() {
            await this.$fluro.resetCache();
            await this.init();
            this.$refs.entryDialog.close();
        },
        openBatchSubmitDialog(competition) {
            let entries = this.entries.filter(
                (entry) =>
                    entry.status == "active" &&
                    !entry?.data?.submitted &&
                    entry?.data?.competition?._id == competition._id
            );

            let model = {
                competition,
                entries,
            };

            if (entries.length) {
                this.$refs.submitDialog.open(
                    model,
                    `You currently have <b>${entries.length} entries</b> that are in a state where they can be submitted. Are you sure you want to submit <b>${entries.length} entries</b> in <b>${competition.title}</b>? This will finalise your submissions and you will not be able to update them further.`
                );
            }
        },
        async submit(model) {
            model.competition.submitting = true;

            try {
                let payload = {
                    userToken: this.$fluro.auth.getCurrentToken(),
                    entries: model.entries,
                };

                let response = await this.$fluro.api
                    .post("/reaction/spark/62a95d1620126e18f0567f22", payload)
                    .then(({ data }) => data);
                console.log("RESPONSE", response);

                if (response.result) {
                    console.log("ERROR", response.result);
                    this.error = true;
                    this.errorMessage = response.result;
                } else {
                    await this.init();

                    this.$refs.submitDialog.close();
                }
            } catch (err) {
                console.log("ERROR", err);
                this.error = true;
                this.errorMessage = err.response.data.message;
            }
        },
        open(entry) {
            this.$router.push({ name: "my-submission", params: { id: entry._id } });
        },
    },

    async mounted() {
        await this.init();
    },
};
</script>

<style lang="scss"></style>
