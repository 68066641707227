<template>
    <div class="cart-item-outer">
        <p class="primary--text small--text" v-if="title">{{ title }}</p>

        <div class="buttons">
            <!-- <pre>{{ sum }}</pre> -->
            <div class="button-outer" v-for="(option, index) in options" :key="index">
                <div class="d-flex flex-column align-start full-width" v-if="option">
                    <p class="x-small--text mb-1" v-if="option.data && option.data.displayTitle">
                        {{ option.data.displayTitle }}
                    </p>
                    <b>{{ option.data.entries }} {{ plural(option.data.entries) }}</b>
                </div>

                <div class="cart-buttons d-flex align-center">
                    <v-btn class="xx-small" fab x-small color="primary" @click="minus(option)">
                        <v-icon>mdi-minus</v-icon>
                    </v-btn>

                    <v-text-field
                        :key="key"
                        placeholder="0"
                        disabled
                        v-model="cart[option._id]"
                        type="number"
                        class="field mx-3"
                        dense
                        filled
                        solo
                        flat
                        hide-details
                    ></v-text-field>

                    <v-btn class="xx-small" fab x-small color="primary" @click="plus(option)">
                        <v-icon>mdi-plus</v-icon>
                    </v-btn>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import _ from "lodash";

export default {
    name: "cart-item-field",

    props: {
        value: [String, Object, Number, Array],
        title: String,
        selected: Object,
        disabled: Boolean,
        loading: Boolean,
        competition: Object,
        myCompetitions: Array,
        printEntry: Object,
    },

    data() {
        return {
            model: this.value,
            current: this.selected,
            cart: {},
            error: false,
            processing: false,
            valid: false,
            key: 0,
            max: 22, //TO DO make a max
            sum: 1,
            array: [],
        };
    },

    computed: {
        myComp() {
            let selectedComp = this.competition;
            return this?.myCompetitions?.find((competition) => competition._id == selectedComp._id);
        },
        options() {
            let options = this.current?.options;

            let selectedComp = this.competition;
            let myComp = this.myComp;
            console.log(options);
            // If there is no comp, they haven't entered so all good.
            let entries = myComp?.entries?.length || 0;

            // The maximum amount of entries per competition
            let max = this.max;

            options = options.filter((option) => {
                let numEntries = option?.data?.entries;

                if (numEntries && numEntries > 0) {
                    // User hasn't entered into the comp before
                    if (!myComp) return option;

                    // It's a digital competition so they can have as many as they want
                    if (selectedComp?.data?.type == "digital") return option;

                    // If their current entry number + the bundle entry number is less than the maximum allowed
                    if (numEntries + entries <= max) return option;
                }
            });

            options = options.filter((option) => {
                //Filter by date
                console.log(option);
                if (option.definition == "bundle") {
                    const today = new Date();
                    today.setHours(0, 0, 0, 0);

                    const bundleStartDate = new Date(option.data.startDate);
                    bundleStartDate.setHours(0, 0, 0, 0);
                    const bundleEndDate = new Date(option.data.endDate);
                    bundleEndDate.setHours(0, 0, 0, 0);
                    console.log(bundleStartDate.getTime(), today.getTime());
                    // Check if bundle's start date is same or after today
                    // and bundle's end date is strictly in the future
                    console.log("Filtering", option);

                    return bundleStartDate.getTime() <= today.getTime() && bundleEndDate.getTime() > today.getTime();
                }
            });
            console.log("Filtered", options);
            return options;
        },
    },

    methods: {
        plural(entries) {
            if (entries > 1) return "Entries";
            return "Entry";
        },
        plus(option) {
            // let myComp = this.myComp;

            // let entries = myComp?.entries?.length || 0;

            // for (var key in this.cart) {
            //     let num = this.cart[key];

            //     this.sum = num * option?.data?.entries;
            // }

            // // The maximum amount of entries per competition
            // let max = this.max;

            // if (entries + this.sum < max) {
            //     if (!this.cart[option._id]) this.cart[option._id] = 0;
            //     this.cart[option._id]++;

            //     this.format();

            //     this.key++;
            // }

            if (!this.cart[option._id]) this.cart[option._id] = 0;
            this.cart[option._id]++;

            this.format();

            this.key++;
        },
        minus(option) {
            // for (var key in this.cart) {
            //     let num = this.cart[key];

            //     if (!num) num = 1;

            //     this.sum = num * option?.data?.entries;
            // }

            if (this.cart[option._id] && this.cart[option._id] > 1) this.cart[option._id]--;
            else delete this.cart[option._id];

            this.format();

            this.key++;
        },
        format() {
            let cart = this.cart;

            let array = Object.keys(cart).map((key, value) => {
                return {
                    _id: key,
                    value: cart[key],
                };
            });

            this.array = array;

            this.$emit("input", array);
        },
    },
};
</script>

<style lang="scss">
.cart-item-outer {
    width: 100%;

    .buttons {
        margin: -12px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        flex-wrap: wrap;

        .button-outer {
            padding: 12px;
            width: 100%;
            display: flex;
            align-items: center;
            justify-content: space-between;

            .field {
                width: 60px;
            }

            /* Chrome, Safari, Edge, Opera */
            input::-webkit-inner-spin-button,
            input::-webkit-outer-spin-button {
                -webkit-appearance: none;
                margin: 0;
            }

            input {
                text-align: center;
            }

            /* Firefox */
            input[type="number"] {
                -moz-appearance: textfield;
            }
        }
    }
}
</style>
